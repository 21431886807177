:root {
  --darkPrimary: #0d2137;
  --darkSecondary: #93dff8;
  --hover: #769493;
}

.dark {
  min-height: 100vh;
  background-color: var(--darkPrimary);
  color: var(--darkSecondary);
}

.dark .Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--darkSecondary);
}

.dark .Navbar .buttonGroup {
  display: flex;
  background-color: var(--darkSecondary);
}

.dark .navButton {
  background-color: var(--darkSecondary);
  padding-inline: 18px;
  border: none;
  text-align: center;
  cursor: pointer;
}

.dark .navButton button {
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.dark .navButton:hover {
  background-color: var(--hover);
}

.dark .iconButton button {
  background-color: var(--darkSecondary);
  border-radius: 50%;
  padding: 5px;
  border: none;
  margin-right: 10px;
}

.dark .iconButton button:hover {
  background-color: var(--hover);
  border-radius: 50%;
  height: fit-content;
}

.dark .imageButton {
  pointer-events: none;
  opacity: 100%;
}

.dark .baseCard {
  color: var(--darkSecondary);
  background-color: var(--darkPrimary);
  height: fit-content;
  width: 90%;
  border-style: solid;
  border-width: 2px;
  border-color: var(--darkSecondary);
  margin: 20px;
  border-radius: 7px;
}

.dark .baseCard h4 {
  margin-left: 10px;
}

.dark .baseCard p {
  padding: 10px;
}

.dark .cardHeader {
  height: 50px;
  border-radius: 5px 5px 0px 0px;
  background-color: var(--darkSecondary);
  color: var(--darkPrimary);
  display: flex;
  align-items: center;
}

.dark .cardHeader h3 {
  margin-left: 20px;
}

.dark .cardHeader h5 {
  margin-left: 20px;
}

.dark .cardContent {
}

.dark .hyperlink a {
  color: white;
}

.dark .textFrame {
  position: relative;
  padding: 8px;
  width: fit-content;
  margin-top: 25px;
}

.dark .pageHeader {
  margin-left: 75px;
}

.dark .textFrame::before,
.dark .textFrame::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: transparent;
  border: 2px solid var(--darkSecondary);
  border-radius: 1px;
}

.dark .textFrame::before {
  top: 0;
  left: 0;
  border-right: none;
  border-bottom: none;
}

.dark .textFrame::after {
  bottom: 0;
  right: 0;
  border-left: none;
  border-top: none;
}

.dark .textFrame * {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.dark .imageBox {
  border: 2px solid var(--darkSecondary);
  padding: 9px;
  border-radius: 5px;
}
