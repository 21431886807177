:root {
  --lightPrimary: #f5f5f5;
  --lightSecondary: #93dff8;
  --hover: #769493;
  --darkPrimary: #0d2137;
}

.light {
  min-height: 100vh;
  background-color: var(--lightPrimary);
  color: black;
}

.light .Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--lightSecondary);
}

.light .Navbar .buttonGroup {
  display: flex;
  background-color: var(--lightSecondary);
}

.light .navButton {
  background-color: var(--darkSecondary);
  padding-inline: 18px;
  border: none;
  text-align: center;
  cursor: pointer;
}

.light .navButton button {
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.light .navButton:hover {
  background-color: var(--hover);
}

.light .iconButton button {
  background-color: var(--lightSecondary);
  border-radius: 50%;
  border: none;
  margin-right: 10px;
}

.light .iconButton button:hover {
  background-color: var(--hover);
  border-radius: 50%;
  padding: 5px;
  height: fit-content;
}

.light .imageButton {
  pointer-events: none;
  opacity: 100%;
}

.light .baseCard {
  background-color: var(--lightPrimary);
  height: fit-content;
  width: 90%;
  margin: 20px;
  border-radius: 7px;
  box-shadow: 0px 4px 5px #a5a5a5;
}

.light .baseCard h4 {
  margin-left: 10px;
}

.light .baseCard p {
  margin-left: 10px;
  padding-bottom: 10px;
  margin-right: 10px;
}

.light .cardHeader {
  height: 50px;
  border-radius: 5px 5px 0px 0px;
  background-color: var(--lightSecondary);
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 5px #a5a5a5;
}

.light .cardHeader h3 {
  margin-left: 20px;
}

.light .cardHeader h5 {
  margin-left: 20px;
}

.light .hyperlink a {
  color: blue;
}

.light .textFrame {
  position: relative;
  padding: 8px;
  width: fit-content;
  margin-top: 25px;
}

.light .pageHeader {
  margin-left: 75px;
}

.light .textFrame::before,
.light .textFrame::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: transparent;
  border: 2px solid black;
  border-radius: 1px;
}

.light .textFrame::before {
  top: 0;
  left: 0;
  border-right: none;
  border-bottom: none;
}

.light .textFrame::after {
  bottom: 0;
  right: 0;
  border-left: none;
  border-top: none;
}

.light .textFrame * {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.light .imageBox {
  border: 2px solid var(--darkPrimary);
  padding: 9px;
  border-radius: 5px;
}
